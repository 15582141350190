import { Values } from "../pages/sendCodeForm/SendCodeForm";

 // Synchronous validation
 export const sendCodeValidate = (values: Values) => {
    const errors: any = {};
  
    if (!values.email) {
      errors.email = 'Email required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    };
    return errors;
  };
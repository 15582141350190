import React from 'react'
import { Box } from "@mui/material";
import './hourglass.scss'

const HourGlassLoader = ({loading}: any) => {
  return (
    <Box>
        <svg className={`hourglass ${loading ? "loading": '' }`} viewBox="-4 0 24 24" id="meteor-icon-kit__solid-hourglass" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 19H7V12C7 12.4421 6.8539 12.8842 6.5617 13.2494L3.21913 17.4276C3.07728 17.605 3 17.8253 3 18.0523V19zM3.00137 6H12.9986C12.9995 5.9826 13 5.96515 13 5.94766V2H3V5.94766C3 5.96515 3.00046 5.9826 3.00137 6H3.00137zM9 12V19H13V18.0523C13 17.8253 12.9227 17.605 12.7809 17.4276L9.4383 13.2494C9.1461 12.8842 9 12.4421 9 12zM15 22C15.5523 22 16 22.4477 16 23C16 23.5523 15.5523 24 15 24H1C0.44772 24 0 23.5523 0 23C0 22.4477 0.44772 22 1 22V18.0523C1 17.3711 1.23184 16.7102 1.65739 16.1783L5 12L1.65739 7.82174C1.23184 7.2898 1 6.62887 1 5.94766V2C0.44772 2 0 1.55228 0 1C0 0.447715 0.44772 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2V5.94766C15 6.62887 14.7682 7.2898 14.3426 7.82174L11 12L14.3426 16.1783C14.7682 16.7102 15 17.3711 15 18.0523V22z" fill="#758CA3"/></svg>
    </Box>
  )
}

export default HourGlassLoader
export const superAdminLoginRoute = '/admin/super/login';
export const loginRoute = '/login';
export const officeRoute = '/admin/office';
export const locationRoute = '/admin/location';
export const deviceRoute = '/admin/device';
export const organizationRoute = '/admin/organization';
export const superAdminRoute = '/superadmin';
export const taskRoute = '/api/task';
export const createUserRoute = '/create-user';
export const usersRoute = 'users';
export const modelRoute = 'models';